.Footer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 15px;
}

.Footer__right-buttons {
  display: grid;
  grid-auto-flow: column;
  margin: auto 0;
  grid-gap: 15px;
}
