@import "./constants.scss";

.MytoByteDetailModal__root {
  $modalWidth: 750px;
  top: 50px;
  width: $modalWidth;
  left: calc(50% - #{$modalWidth}/ 2);
  position: relative;
  background-color: rgb(54, 58, 75);
  border: 1px;
  border-radius: $border-radius;
}

.MytoByteDetailModal__body {
  display: grid;
  grid-template-columns: 45px 1fr 45px;
}

.MytoByteDetailModal__closeButton {
  position: absolute;
  top: 0;
  right: 0;
}

.MytoByteDetailModal__navigation-button {
  height: 25px;
  width: 25px;
  color: white;
}

.MytoByteDetailModal__navigation-button-container {
  margin: auto;
}

@media (max-width: $mobile-breakpoint) {
  .MytoByteDetailModal__root {
    width: 100%;
    height: 100%;
    position: static;
    border-radius: 0;
    overflow: scroll;
  }

  .MytoByteDetailModal__body {
    grid-template-columns: 25px 1fr 25px;
  }
}
