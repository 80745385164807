@import "./constants.scss";

.MytoByteDetailView__root {
    padding: 12px;
}

.MytoByteDetailView__header{
    text-align: center;
    margin: 0 auto;
    padding-top: 12px;
    font-weight: 500;
    font-size: 24px;
}

.MytoByteDetailView__iconSection {
    text-align: right;
}

.MytoByteDetailView__icon{
    padding: 3px;
}

.MytoByteDetailView__icon-pretext {
    font-size: 16px;
    position: relative;
    top: -8px;
}

.MytoByteInfoSection__root {
    display: grid;
    grid-template-columns: 1fr 1fr;
    padding-bottom: 24px;
}

.MytoByteInfoSection__Image{
    margin: 0 auto;
    display: block;
    padding: 32px;
    height: 192px;
}

.ClaimableMytoActionSection__roots{
    text-align: center;
}

@media (max-width: $mobile-breakpoint) {
    .MytoByteDetailView__header {
        font-size: 36px;
    }

    .MytoByteInfoSection__root{
        grid-template-columns: 1fr;
    }

    .MytoByteDetailView__root {
        padding: 24px;
    }

    .MytoByteInfoSection__Image {
        height: 250px;
    }
}