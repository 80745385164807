@import "./constants.scss";

.MytoByteGridCell {
  background-color: rgba(white, 0.2);
  border-radius: $border-radius;
  cursor: pointer;
  text-align: center;
  width: 100%;
  box-sizing: content-box;
  -webkit-transition: all 0.2s cubic-bezier(0.165, 0.84, 0.44, 1);
  transition: all 0.2s cubic-bezier(0.165, 0.84, 0.44, 1);
}

.MytoByteGridCell::after {
  content: "";
  border-radius: $border-radius;
  position: absolute;
  z-index: -1;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
  opacity: 0;
  -webkit-transition: all 0.2s cubic-bezier(0.165, 0.84, 0.44, 1);
  transition: all 0.2s cubic-bezier(0.165, 0.84, 0.44, 1);
}

.MytoByteGridCell:hover {
  -webkit-transform: scale(1.03, 1.03);
  transform: scale(1.03, 1.03);
}

.MytoByteGridCell:hover::after {
  opacity: 1;
}

.MytoByteGridCell-image {
  margin: auto;
}

.MytoByteGridCell-image-not-minted {
  padding: 38px;
}

.MytoByteGridCell-image-force-large {
  width: 256px;
}
