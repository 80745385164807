@import "constants.scss";

.Community {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

@media (max-width: $mobile-breakpoint) {
  .Community {
    flex-direction: column;
  }

  .Community__detail {
    margin-bottom: 15px;
  }

  .Community__widget {
    width: 100%;
  }
}
