@import "./constants.scss";

.Roadmap__card-container {
  display: grid;
  grid-auto-flow: row;
  grid-row-gap: 20px;
  max-width: 650px;
  margin: auto;
}

.Roadmap__divider {
  height: 250px;
  width: 2px;
  background: white;
  margin: 0 20px;
}

.test {
  background-color: white;
}

.Section__root:nth-of-type(odd) {
  .Roadmap__card {
    background-color: #212428 !important;
  }
}

.Section__root:nth-of-type(even) {
  .Roadmap__card {
    background-color: #5d5d5d !important;
  }
}

@media (max-width: $mobile-breakpoint) {
  .Roadmap__section-number {
    display: none;
  }

  .Roadmap__divider {
    display: none;
  }
}
