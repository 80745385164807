@import "./constants.scss";

.Claimable_Myto__grid {
  display: grid;
  grid-template-columns: repeat(5, minmax(0, 1fr));
  grid-gap: 5px;
}

.Claimable_Myto__cell-container {
  text-align: center;
}

.Claimable_Myto__claim-button {
  padding: 6px;
}

@media (max-width: $tablet-breakpoint) {
  .Claimable_Myto__grid {
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }
}

@media (max-width: $mobile-breakpoint) {
  .Claimable_Myto__grid {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }
}
