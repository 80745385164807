@import "./constants.scss";

.Hero {
  display: grid;
  grid-auto-flow: column;
  align-items: center;
  justify-content: space-between;
  grid-gap: 15px;
}

.Hero__button-box button {
  margin: 6px;
}

@media (max-width: $mobile-breakpoint) {
  .Hero {
    grid-auto-flow: row;
  }
}
