@import "../constants.scss";

.Section__root {
  background-color: $primary_section_color;
  .custom-shape-divider-top-1632884027 {
    position: relative;
    top: 0;
    left: 0;
    width: 100%;
    overflow: hidden;
    line-height: 0;
    fill: $primary_section_color;
    background-color: $secondary_section_color;
  }
}

.Section__root:nth-of-type(odd) {
  background-color: $secondary_section_color;
  .custom-shape-divider-top-1632884027 {
    background-color: $primary_section_color;
    fill: $secondary_section_color;
  }
}

.Section__content {
  align-items: center;
  justify-content: center;
  margin-left: auto;
  margin-right: auto;
  max-width: 1200px;
  margin-bottom: 24px;
  padding-left: 60px;
  padding-right: 60px;
  overflow: hidden;
}

.Section__remove-bottom-padding {
  margin-bottom: 0;
}

.SectionDivider__svg {
    position: relative;
    top: -1px
  }

@media (max-width: $mobile-breakpoint) {
  .Section__content {
    padding-left: 15px;
    padding-right: 15px;
  }
}
