h1 {
  margin: 0;
}

.Home-link {
  color: #61dafb;
}

body {
  font-size: 20px;
}