@import "./constants.scss";

.Token {
  background-color: grey;
}

.Minted-Token {
  background-color: red;
}

.Claimable-Token {
  background-color: limegreen;
}

.Claimable-Token:hover {
  background-color: palegreen;
  cursor: pointer;
}

.MytoDex__navigation {
  text-align: center;
}

.MytoDex__grid {
  display: grid;
  grid-template-columns: repeat(5, minmax(0, 1fr));
  grid-gap: 5px;
}

@media (max-width: $tablet-breakpoint) {
  .MytoDex__grid {
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }
}

@media (max-width: $mobile-breakpoint) {
  .MytoDex__grid {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }
}
