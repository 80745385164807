@import "./constants.scss";

.Page-Header {
  flex-direction: row;
  display: flex;
  justify-content: space-between;
  padding: 15px 60px;
  border-bottom: 1px solid grey;
  background-color: $primary_section_color;
}

.Page-Header-left-buttons {
  margin: auto 0;
  display: grid;
  grid-auto-flow: column;
  grid-gap: 15px;
  color: white;
}

.Page-Header-left-buttons > * {
  margin-right: 5px;
}

.Page-Header__connect-button {
  margin: auto 0;
}

.Page-Header__mobile-hamburger {
  display: none;
}

@media (max-width: $mobile-breakpoint) {
  .Page-Header {
    padding: 5px 15px;
  }

  .Page-Header-left-buttons {
    display: none;
  }

  .Page-Header__connect-button {
    text-align: right;
  }

  .Page-Header__mobile-hamburger {
    display: inline;
    color: white;
  }
}
