@import "./constants.scss";

.Collection__grid {
  display: grid;
  grid-template-columns: repeat(5, minmax(0, 1fr));
  grid-gap: 5px;
  margin: auto;
}

@media (max-width: $tablet-breakpoint) {
  .Collection__grid {
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }
}

@media (max-width: $mobile-breakpoint) {
  .Collection__grid {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }
}
